@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	50% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}
	100% {
		-webkit-transform: scale3d(1.05, 1.05, 1);
		transform: scale3d(1.05, 1.05, 1);
	}
}
@keyframes pulse {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	50% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1);
		transform: scale3d(1.1, 1.1, 1.1);
	}
	100% {
		-webkit-transform: scale3d(1.05, 1.05, 1);
		transform: scale3d(1.05, 1.05, 1);
	}
} 