.floating {
    animation: float 1.7s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translateY(1px);
    }
    50% {
        transform: translateY(-3px);
    }
    100% {
        transform: translateY(1px);
    }
}